<template>
<!-- 平台资讯 -->
<div class="infoList">
  <div class="top"></div>
  <div class="list w">
    <div class="item" v-for="item in dynamicList" :key="item.id">
      <div class="title">
        {{item.title}}
      </div>
      <div class="sub">
        <div class="date">
          <img src="@/assets/imgs/info/icon_clock.svg" alt="">
          {{item.created_at}}
        </div>
        <div class="eye">
          <img src="@/assets/imgs/info/icon_eye.svg" alt="">
          {{item.pv}}
        </div>
      </div>
      <div class="p" v-html="item.content"></div>
      <div class="link" @click="$router.push({path:'/infoDetail',query:{
        id:item.id
      }})">查看全文</div>
    </div>
  </div>
  <div class=" w">

    <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="[10, 20, 30]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</div>
</template>

<script>
import utils from '@/utils/utils.js'
export default {
  data() {
    return {
      // 最新动态
      dynamicList: [],
      page: 1,
      limit: 10,
      total: 0,
    }
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.getDynamicList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getDynamicList()
    },
    getDynamicList() {
      utils.get('/api/merchants/new/dynamic/list', {
        page: this.page,
        limit: this.limit
      }).then(res => {
        if (res.err_code == 0) {
          this.dynamicList = res.data.data
          this.total = res.data.total
        }
      })
    },
  },
  created() {
    this.getDynamicList()
  }
}
</script>

<style lang="less" scoped>
.infoList {
  margin-top: 60px;
  .top {
    background: url('../assets/imgs/info/banner.png') center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 310px;
    color: #fff;
    // padding-top: .56rem;
  }

  .list {
    margin: 60px auto 100px;

    .item {
      border-bottom: 1px solid #EFEFEF;
      padding: 40px 0;

      .title {
        color: #333333;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 17px;

        width: 850px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .sub {
        color: #333333;
        display: flex;
        margin-bottom: 20px;
      }

      .date {
        display: flex;
        align-items: center;
        margin-right: 60px;
        img{
          margin-right: 10px;
        }
      }
      .eye{
           display: flex;
        align-items: center;
        img{
          margin-right: 10px;
        }
      }

      .p {
        color: #666;
        font-size: 12px;
        margin-bottom: 20px;
        line-height: 20px;

        width: 1200px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;

      }

      .link {
        color: #01A252;
        cursor: pointer;
      }

    }
  }

  .pagination {
    text-align: right;
  }
}
</style>
