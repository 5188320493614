<template>
<div class="test">
  <div class="pic">
      <div class="img_box">
        <img src="@/assets/imgs/OR_code.jpg" alt="">
      </div>
      <p>扫一扫，小程序预约</p>
    </div>
</div>
</template>

<script>
import utils from '@/utils/utils.js'
export default {
  data() {
    return {
      types: [],
      sources: [],
      citys: [],
      form: { // 企业名称
        company_name: null,
        // 联系人名称
        contact_name: null,
        // 企业类型
        company_category: null,
        // 手机号码
        contact_phone: null,
        // 验证码
        valid_code: null,
        // 邮箱
        contact_email: null,
        // 了解途径
        source: null,
        // 所在城市
        area: null,
      },
      // 试用说明
      testShow: false,
      checked: false,
      // 获取验证码
      codeShow: true,
      count: 60,
      // remainShow
      remainShow: false,

      isSubmit: false,

      valiName: (rule, value, callback) => {
        let reg = /^[\u2E80-\u9FFF]+$/; //Unicode编码中的汉字范围
        console.log(`value`, value)
        if(!value) return  callback([new Error('姓名不能为空')]);
        if (value && value.length > 0) {
          if (!reg.test(value)) {
            callback([new Error('姓名输入不合法')]);
          } else {
            callback();
          }
        }
      },

      valiPhone: (rule, value, callback) => {
        const testPhone = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
        if(!value) return  callback([new Error('号码不能为空')]);
        if (testPhone.test(value)) {
          callback()
        } else {
          callback(new Error('请检查手机号码格式'))
        }
      }
    }
  },
  watch: {
    form: {
      handler(val, oldVal) {
        if (val.company_name && val.contact_name && val.company_category && val.contact_phone && val.valid_code && val.contact_email && val.source && this.checked) {
          this.isSubmit = true;
        } else {
          this.isSubmit = false;
        }
      },
      deep:true
    },
    checked(val){
        if (this.form.company_name && this.form.contact_name && this.form.company_category && this.form.contact_phone && this.form.valid_code && this.form.contact_email && this.form.source && val) {
          this.isSubmit = true;
        } else {
          this.isSubmit = false;
        }
    }
  },
  created() {
    this.getCompanyConfig()
  },
  methods: {
    linkTo(id, category_id) {
      window.open(`https://kefu.pinshangtong.cn/rule/detail?detailId=${id}&category_id=${category_id}`)
    },
    linkToPC() {
      window.open('https://peixun.pinshangtong.cn/login')
    },
    // 获取企业类型、了解途径
    getCompanyConfig() {
      utils.get('/api/v2/user/application/company/config').then(res => {
        console.log(`获取企业类型、了解途径`, res)
        if (res.err_code == 0) { 
          this.types = Object.values(res.data.company_category)
          this.sources = Object.values(res.data.source_channel)
          this.citys = Object.values(res.data.company_province_city)
        }
      }) 
    },
    // 获取验证码
    sendValidCode() {
      const phoneReg = /^1([0-9][0-9])[0-9]{8}$/
      if (!this.form.contact_phone) {
        this.$message.warning('请输入手机号', 1);
        return;
      }
      if (!phoneReg.test(this.form.contact_phone)) {
        this.$message.warning('手机号码格式不正确', 1);
        return;
      }

      if (!this.timer) {
        this.count = 60;
        this.codeShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.codeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      utils.post('/api/merchants/valid', {
        mobile: this.form.contact_phone
      }).then(res => {
        if (res.err_code == 0) {
          this.$message.success('发送成功')
        }
        if (res.err_code == 1) {
          this.$message.warning('发送失败')
        }
      })
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.checked) {
            this.$message.warning('请同意平台协议')
            return
          }
          utils.post('/api/merchants/application/company', this.form).then(res => {
            if (res.err_code == 0) {
              this.remainShow = true
            }
            if (res.err_code == 1) {
              this.$message.error(res.err_msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    eachRuleClick(id, category_id) {

      window.open(`https://kefu.pinshangtong.cn/rule/detail?detailId=${id}&category_id=${category_id}`)

    },
  },
}
</script>

<style lang="less" scoped>
.test {
  .pic {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img_box {
        width: 600px;
        height: 600px;
        margin-bottom: 20px;
        img{
          width: 100%;
          height: 100%;
        }
      }

      p {
        color: #666;
        font-size: 14px;
      }

    }
}
</style>
