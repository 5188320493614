<template>
<div class="infoDetail w">
  <div class="title" :title="detailObj.title">
    {{detailObj.title}}
  </div>
  <div class="p" v-html="detailObj.content">
  </div>
</div>
</template>

<script>
import utils from '@/utils/utils.js'
export default {
  data() {
    return {
      detailObj: {
        title: null,
        content: null,
      }
    }
  },
  created() {
    const {
      id
    } = this.$route.query
    this.getDetail(id)
  },
  methods: {
    getDetail(id) {
      utils.get('/api/merchants/dynamic/detail', {
        id,
      }).then(res => {
        if (res.err_code == 0) {
          this.detailObj = res.data
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.infoDetail {
  padding: 100px 0;
  margin-top: 60px;

  .title {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 17px;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 20px;

    width: 1100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .p {
    padding-top: 30px;
    //  color: #666;
    //     font-size: 12px;
    //     margin-bottom: 20px;
    //     line-height: 20px;
  }
}
</style>
