<template>
<div>
  <div class="banner">
    <div class="w">
      <!-- <img src="@/assets/imgs/banner/banner4.png" alt=""> -->
      <div class="btn" @click="linkTo">
        立即试用
      </div>
    </div>
  </div>
  <section>
    <!-- 关于我们 -->
    <div class="aboutus w">
      <div class="img_box bounceInLeft animated wow">
        <img src="@/assets/imgs/aboutus/about.png" alt="">
      </div>
      <div class="text">
        <h3 class="fadeInUp animated wow ">关于我们</h3>
        <p class="bounceInRight animated wow">
          重庆品商通网络科技有限公司（简称“品商通”），成立于2019年，由欧菲斯办公伙伴集团全资控股，是一家零售产业数字化精准营销及赋能服务综合解决方案提供商。品商通致力于利用契合时代发展趋势的现代化互联网技术，为零售产业群体赋能。品商通依托欧菲斯办公伙伴集团多年来在产业内的发展经验和资源积淀，通过构建和运营的品商通平台，服务产业群体业务数字化转型和商业模式创新，赋能产业群体迈入降费提效发展新时代。
        </p>
      </div>
    </div>
    <!-- 关于平台 -->
    <div class="aboutPlatforms">
      <div class="w">
        <div class="text">
          <h3 class="fadeInUp animated wow ">关于平台</h3>
          <p class="bounceInLeft animated wow">
            品商通平台是由重庆品商通网络科技有限公司创建的一个B2B2C新零售产业互联网平台。平台以零售产业知识信息作为内容核心，借助直播、音视频、图文信息等方式，在零售产业相关群体中进行交互传递，对产业群体进行赋能；并在赋能的过程中，完成品牌或产品对精准客群的精细营销和刺激销售，提升品牌及产品的知名度和销量。品商通平台致力于利用符合时代发展趋势的现代化互联网技术，构建一个场景化内容、沉浸式体验、精准化营销的产业互联网平台，数
            字化赋能产业群体。 </p>
        </div>

      </div>
    </div>
    <!-- 共赢5G时代 -->
    <div class="win5GTimes w">
      <div class="text">
        <h3 class="fadeInUp animated wow ">共赢5G时代</h3>
        <p class="bounceInLeft animated wow">
          随着5G技术的愈发成熟和大面积商用，移动互联网进入到一个更加高速、高效的时代,零售产业的“互联网+”也将毫不例外地迎来另一轮新的发展。以短视频、在线直播、甚至VR/AR投影、全息投影等内容承载形式的内容型、体验型零售产业模式或将成为未来主流模式。重庆品商通网络科技有限公司探索以产业知识信息内容为引导，通过为企业提供功能多样的系统以及专业的平台操作指导、专业的讲授技巧、内容体系构建技巧，为新零售产业各群体赋能，共赢5G新时代。
        </p>
      </div>
      <div class="img_box bounceInRight animated wow"><img src="@/assets/imgs/aboutus/5G11.png" alt=""></div>
    </div>
  </section>
</div>
</template>

<script>
export default {
  methods: {
    linkTo() {
      window.open('https://peixun.pinshangtong.cn/login?site=1')
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  height: 500px;
  background: url("../assets/imgs/banner/banner4.png") no-repeat center;
}

.banner .w {
  height: 100%;
  margin-top: 60px;
}

.banner .btn {
  position: absolute;
  top: 60%;
  left: 20%;
  width: 143px;
  height: 40px;
  background: #4DD3F7;
  border-radius: 8px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #03489F;

}

/* 关于我们 */
.aboutus {
  display: flex;
  margin: 100px auto;
  align-items: flex-start;
  height: 400px;
}

.aboutus .img_box {
  width: 632px;
  height: 290px;
  margin-right: 50px;
}

.aboutus .text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

.aboutus .text h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: left;

  color: #010920;
  margin-bottom: 20px;
}

.aboutus .text p {
  font-size: 12px;
  line-height: 20px;

  color: #000000;
  text-indent: 2em;
}

/* 关于平台 */
.aboutPlatforms {
  height: 420px;
  width: 100%;
  background: url("../assets/imgs/aboutus/bg.png") no-repeat center;
  color: #fff;
  padding-top: 80px;

}

.aboutPlatforms .text {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.aboutPlatforms .text h3 {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}

.aboutPlatforms .text p {
  width: 478px;
  font-size: 12px;
  line-height: 20px;
  text-indent: 2em;
}

/* 共赢5G时代 */
.win5GTimes {
  display: flex;
  margin: 100px auto;
  align-items: flex-start;
}

.win5GTimes .text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

}

.win5GTimes .text h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}

.win5GTimes .text p {
  width: 491px;
  font-size: 12px;
  line-height: 20px;
  text-indent: 2em;
}

.win5GTimes .img_box {
  margin-left: 63px;
  width: 638px;
  height: 414px;
}
</style>
